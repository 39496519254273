/**
 * Only Numbers
 */
function isOnlyNumbers(str) {
  const regex1 = /^[0-9]+$/;
  return regex1.test(str);
}

/**
 * Only Numbers from 1 till 99
 */
function isOnlyNumbersFrom1Till99(str) {
  const regex1 = /^(?:[1-9][0-9]?|99)$/;
  return regex1.test(str);
}

/**
 * Only Numbers From 1 till 100
 */
function isOnlyNumbersFrom1till100(str) {
  const regex1 = /^(100|[1-9][0-9]?|0[1-9]|100)$/;
  return regex1.test(str);
}

export { isOnlyNumbers, isOnlyNumbersFrom1Till99, isOnlyNumbersFrom1till100 };
