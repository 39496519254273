import { useState } from 'react';
import { Answer, QuestionModel } from 'src/api';
import { getAnswer, useDependencyCheck } from '../../app/pages/survey/hooks';
import { navigate } from 'src/core';

/**
 * Use Progress Data
 */
const useProgressData = (
  history: any,
  id: string,
  sendAnswers: () => void,
  autoclose: boolean,
  autocloseMinutes: number,
  questions: QuestionModel[],
  results: any
) => {
  const [current, setCurrent] = useState(0);
  const [questionsIndexes, setQuestionsIndexes] = useState<number[]>([0]);
  const [isPrevClick, setPrevClick] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const setNextQuestion = (answer: Answer) => {
    const nextQuestionIndex = questions.findIndex(
      question => question.questionId === answer.next_question
    );

    if (questionsIndexes.includes(nextQuestionIndex)) {
      setCurrent(nextQuestionIndex);
      return;
    }

    setQuestionsIndexes(prev => [...prev, nextQuestionIndex]);

    return setCurrent(nextQuestionIndex);
  };

  const next = (answer?: Answer & { skip?: boolean }, navFunc?: Function) => {
    setPrevClick(false);
    if (autoclose) {
      const delayMilliseconds = autocloseMinutes * 60 * 1000;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setTimeoutId(
        setTimeout(() => {
          sendAnswers();
          navigate(`/summary-autoclose/?id=${id}`, history);
        }, delayMilliseconds)
      );
    }
    if (
      [questions[current], answer].some(
        item => item?.endQuestionary === true && answer?.skip === false
      )
    ) {
      navFunc?.();
      return;
    }

    const answerWithNextQuestion = [answer, questions[current]].find(
      item => item?.next_question
    );

    if (answerWithNextQuestion) {
      setNextQuestion(answerWithNextQuestion);
      return;
    }

    let skip = true;
    let i = 0;

    while (skip) {
      i++;
      if (current + i >= questions.length) {
        // Avoid out-of-bounds access
        break;
      }

      skip = useDependencyCheck({
        question: questions[current + i],
        getAnswer: (questionId: string) => getAnswer(questionId, results)
      });
    }

    if (current + i < questions.length) {
      setQuestionsIndexes(prev => [...prev, current + i]);

      const isLast = current + i === questions.length - 1;
      setCurrent(isLast ? questions.length - 1 : current + i);
    } else {
      setCurrent(questions.length - 1);
    }
  };

  const prev = () => {
    const value = questionsIndexes.indexOf(current);
    setPrevClick(true);

    if (value <= 0) {
      return;
    }

    const previousQuestion = questions[questionsIndexes[value - 1]];

    if (previousQuestion.answers[0].type === 'percents_multiple') {
      const filteredVariants = previousQuestion.answers[0].list_variant.filter(
        listItem => {
          if (!listItem.dependency) {
            return true;
          }

          const {
            answers = '',
            questionId,
            list_variant
          } = listItem.dependency;
          const currentResult = results[questionId];
          const answersList = answers.split('|');

          const getValidAnswers = () => {
            if (list_variant) {
              return list_variant
                .split('|')
                .map(
                  variant =>
                    (currentResult as Record<string, string | number>)[variant]
                );
            }

            if (typeof currentResult === 'string') {
              return [currentResult];
            }

            return Object.keys(currentResult);
          };

          return getValidAnswers()
            .filter(Boolean)
            .map(String)
            .some(text => answersList.includes(text));
        }
      );

      const numbersOfVariants = filteredVariants.length;
      if (numbersOfVariants === 1) {
        setCurrent(questionsIndexes[value - 2]);
        return;
      }
    }
    setCurrent(value != 0 ? questionsIndexes[value - 1] : 0);
    questionsIndexes.pop();
  };

  return { current, next, prev, isPrevClick };
};

export { useProgressData };
