import React from 'react';
import leftArrow from '../../../../assets/images/arrow-left-calendar.svg';

const PrevArrow = () => <img src={leftArrow} alt='Left Arrow' />;

const NextArrow = () => (
  <img
    src={leftArrow}
    alt='Left Arrow'
    style={{ transform: 'rotate(180deg)' }}
  />
);

export { PrevArrow, NextArrow };
