import { useEffect, useMemo, useState } from 'react';
import { FormControlProps, QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import { formattingTime } from 'src/core/utils';

/**
 * <TimeRangePicker /> props type
 */
type TimeRangePickerProps = FormControlProps & {
  /**
   * Question
   */
  question: QuestionModel;
  /**
   * OnChange handler
   */
  onChange: (question: QuestionModel, value: string) => void;
};

/**
 * <TimeRangePicker /> props
 */
const useTimeRangePickerProps = ({
  question,
  value,
  onChange
}: Partial<TimeRangePickerProps>) => {
  const { changeIsQuestionAnswered, changeIsSingleError } = useStore();

  const currentHours = new Date().getHours();
  const currentMinutes = new Date().getMinutes();

  const [hoursStart, setHoursStart] = useState<number>(currentHours);
  const [minutesStart, setMinutesStart] = useState<number>(currentMinutes);
  const [hoursEnd, setHoursEnd] = useState<number>(currentHours + 1);
  const [minutesEnd, setMinutesEnd] = useState<number>(currentMinutes);

  const { answers } = question;
  const { type } = answers[0];

  const startTime = useMemo(() => formattingTime(hoursStart, minutesStart), [
    hoursStart,
    minutesStart
  ]);

  const endTime = useMemo(() => formattingTime(hoursEnd, minutesEnd), [
    hoursEnd,
    minutesEnd
  ]);

  const isTimeRangeValid = (startTime: string, endTime: string): boolean => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;

    return startTotalMinutes < endTotalMinutes;
  };

  const handleChange = (
    type: 'hoursUp' | 'minutesUp' | 'hoursDown' | 'minutesDown',
    setHoursFunction: React.Dispatch<React.SetStateAction<number>>,
    setMinutesFunction: React.Dispatch<React.SetStateAction<number>>
  ) => {
    const actions = {
      hoursUp: () => setHoursFunction(prevHours => (prevHours + 1) % 24),
      minutesUp: () =>
        setMinutesFunction(prevMinutes => (prevMinutes + 1) % 60),
      hoursDown: () => setHoursFunction(prevHours => (prevHours - 1 + 24) % 24),
      minutesDown: () =>
        setMinutesFunction(prevMinutes => (prevMinutes - 1 + 60) % 60)
    };

    actions[type]?.();
  };

  const onChangeStart = (
    type: 'hoursUp' | 'minutesUp' | 'hoursDown' | 'minutesDown'
  ) => handleChange(type, setHoursStart, setMinutesStart);

  const onChangeEnd = (
    type: 'hoursUp' | 'minutesUp' | 'hoursDown' | 'minutesDown'
  ) => handleChange(type, setHoursEnd, setMinutesEnd);

  useEffect(() => {
    const isValidRange = isTimeRangeValid(startTime, endTime);
    changeIsSingleError(!isValidRange);
    const newTime = [startTime, endTime].join('-');

    onChange(question as QuestionModel, newTime);
  }, [hoursStart, minutesStart, hoursEnd, minutesEnd, startTime, endTime]);

  useEffect(() => {
    changeIsQuestionAnswered(!!value);
  }, [value, changeIsQuestionAnswered]);

  return {
    hoursStart,
    minutesStart,
    hoursEnd,
    minutesEnd,
    type,
    onChangeStart,
    onChangeEnd
  };
};

export { useTimeRangePickerProps };
