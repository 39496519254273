import { useEffect, useLayoutEffect, useMemo } from 'react';
import { MultiListVariantDependency, QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import {
  useCurrentAnswer,
  useDemicalError,
  useDependencyNotShow,
  useLimitSell,
  useLimitSellMin,
  useLimitSum,
  useNumberError,
  useValueAsObject
} from '../../../hooks';
import { isString } from '../../../utils';

/**
 * Use Multi Question Params
 */
type useMultipleQuestionParams<T = MultiListVariantDependency> = {
  /**
   * Onchange
   */
  onChange: (question: QuestionModel, value: any) => void;
  /**
   * Question
   */
  question: QuestionModel<T>;
  /**
   * Value
   */
  value: string | Record<string, string>;
};

/**
 * Use Filtered Variants Multi
 */
const useFilteredVariantsMulti = (
  question: QuestionModel<MultiListVariantDependency>
) => {
  const variants = useDependencyNotShow(question?.answers?.[0]?.list_variant);

  const { getAnswer } = useCurrentAnswer();

  return variants.filter(({ dependency }) => {
    if (!dependency) return true;

    const { answers = '', list_variant = '', questionId } = dependency;
    const variants = list_variant.split('|');
    const validAnswers = answers.split('|');

    const currentResult = getAnswer(questionId);
    const currentResultAsObject =
      typeof currentResult === 'string'
        ? {}
        : Object.fromEntries(Object.entries(currentResult));

    const targetArray = Array.isArray(currentResult)
      ? currentResult
      : variants.map(variant => currentResultAsObject[variant]);

    return targetArray
      .filter(Boolean)
      .some(text => validAnswers.includes(text.toString()));
  });
};

/**
 * Use Filtered Variants
 */
const useFilteredVariants = (question: QuestionModel) => {
  const { getAnswer } = useCurrentAnswer();

  const variants = useDependencyNotShow(question?.answers?.[0]?.list_variant);

  return variants.filter(({ dependency, text }) => {
    if (!dependency) {
      return true;
    }

    const { answers = '', questionId, list_variant } = dependency;
    const answersList = answers.split('|');

    const currentDepsAnswer = getAnswer(questionId);

    if (list_variant) {
      return answersList.includes(currentDepsAnswer[list_variant]);
    }

    const targetArray: any =
      (Array.isArray(currentDepsAnswer)
        ? currentDepsAnswer
        : isString(currentDepsAnswer)
        ? currentDepsAnswer.toString().split('|')
        : Object.keys(currentDepsAnswer)) || [];

    return targetArray
      .map(result => (typeof result === 'string' ? result : result?.text) ?? '')
      .filter(Boolean)
      .some(text => answersList.includes(text.toString()));
  });
};

/**
 * Use Multiple Question
 */
const useMultipleQuestion = <T>(
  { onChange, question, value }: useMultipleQuestionParams<T>,
  filterHook:
    | typeof useFilteredVariants
    | typeof useFilteredVariantsMulti = useFilteredVariantsMulti
) => {
  const { changeIsQuestionAnswered, next, prev, isPrevClick } = useStore();

  const filteredVariants = filterHook(question as QuestionModel<any>);

  const currentResult = useValueAsObject(value, () => {
    if (!value || typeof value === 'string') {
      onChange((question as unknown) as QuestionModel, {});
      return;
    }

    const newValue = Object.keys(value)
      .filter(key => filteredVariants.some(({ text }) => text === key))
      .reduce(
        (result, key) => ({
          ...result,
          [key]: value[key]
        }),
        {} as Record<string, string>
      );

    onChange((question as unknown) as QuestionModel, newValue);
  });

  const { isError: isLimitSumError } = useLimitSum(
    question as QuestionModel<any>
  );

  const { isError: isNumberError } = useNumberError(
    question as QuestionModel<any>
  );

  const { isError: isDemicalError } = useDemicalError(
    question as QuestionModel<any>
  );

  const { isError: isLimitSellError } = useLimitSell(
    question as QuestionModel<any>
  );

  const { isError: isLimitSellMinError } = useLimitSellMin(
    question as QuestionModel<any>
  );

  const onFieldChange = (key: string, value: string) => {
    const newValue = {
      ...currentResult,
      [key]: value
    };

    onChange((question as unknown) as QuestionModel, newValue);
    changeIsQuestionAnswered(
      !isLimitSumError &&
        !isLimitSellError &&
        !isLimitSellMinError &&
        Object.keys(currentResult).length === filteredVariants.length
    );
  };

  //is nothing to render skip question
  useLayoutEffect(() => {
    if (filteredVariants.length) return;

    if (isPrevClick) {
      prev();
      return;
    }

    next({
      text: question.text,
      description: question.description,
      skip: true
    });
  }, [question]);

  useEffect(() => {
    const keys = Object.keys(currentResult);

    question.answers[0].type === 'number_multiple'
      ? changeIsQuestionAnswered(
          !isNumberError &&
            !isLimitSumError &&
            !isLimitSellError &&
            !isLimitSellMinError &&
            Object.keys(currentResult).length === filteredVariants.length &&
            keys.every(key => !!currentResult[key])
        )
      : question.answers[0].type === 'demical_multiple'
      ? changeIsQuestionAnswered(
          !isDemicalError &&
            !isLimitSumError &&
            !isLimitSellError &&
            !isLimitSellMinError &&
            Object.keys(currentResult).length === filteredVariants.length &&
            keys.every(key => !!currentResult[key])
        )
      : changeIsQuestionAnswered(
          !isLimitSumError &&
            !isLimitSellError &&
            !isLimitSellMinError &&
            Object.keys(currentResult).length === filteredVariants.length &&
            keys.every(key => !!currentResult[key])
        );
  }, [isLimitSellError, isLimitSellMinError, currentResult, filteredVariants]);

  return useMemo(
    () => ({
      onFieldChange,
      variants: filteredVariants,
      currentResult
    }),
    [onFieldChange, filteredVariants, currentResult]
  );
};

export { useMultipleQuestion, useFilteredVariants, useFilteredVariantsMulti };
