/**
 * Is Valid Inn
 */
const isValidInn = (value: any) => {
  if (value?.match(/\D/)) return false;

  const inn = value?.match(/(\d)/g);
  const hash = [-1, 5, 7, 9, 4, 6, 10, 5, 7];

  let sum = 0;
  for (let j = 0; j < inn?.length - 1; ++j) {
    sum += inn[j] * hash[j];
  }

  let k = sum - 11 * Math.floor(sum / 11);

  if (k == 10) {
    k = 0;
  }

  if (inn?.length > 9) {
    const days_from_70 = value.substr(0, 5);
    const days = days_from_70 - 365 * 70;
    const today = new Date();
    const date_of_b = new Date(1 / 1 / 1900);

    date_of_b.setDate(date_of_b.getDate() + days);

    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(
      date_of_b.getFullYear(),
      date_of_b.getMonth(),
      date_of_b.getDate()
    );
    const utc2 = Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const diffDays = (utc2 - utc1) / _MS_PER_DAY;

    if (diffDays < 6570) {
      //проверка на возраст больше 18 лет
      return false;
    }
    return k == inn[9];
  }

  return false;
};

const isValidPMIMail = (value: any) => {
  if (
    value &&
    (value.indexOf('@pmi.com') >= 0 ||
      value.indexOf('@contracted.pmi.com') >= 0 ||
      value.indexOf('@pmintl.net') >= 0)
  ) {
    return true;
  } else {
    return false;
  }
};

export { isValidInn, isValidPMIMail };
