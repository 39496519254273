import React from 'react';
import classNames from 'classnames';
import { hoc } from '../../utils';
import { useRadioRedesignProps } from './radio-redesign.props';
import styles from './radio-redesign.module.scss';

/**
 * <RadioRedesign />
 */
const RadioRedesign = hoc(
  useRadioRedesignProps,
  ({
    value,
    className,
    disabled,
    label,
    image,
    isError,
    classes,
    imageStyle,
    _onClick
  }) => (
    <div
      className={classNames(
        styles.radio,
        {
          [styles.radioDisabled]: disabled
        },
        className,
        classes?.root
      )}
      onClick={_onClick}
    >
      <div
        className={classNames(
          styles.flag,
          {
            [styles.flag_active]: value,
            [styles.flag_invalid]: isError
          },
          classes?.flag
        )}
      >
        <div
          className={classNames(styles.flag__indicator, {
            [styles.flag__indicator_active]: value
          })}
        />
      </div>

      {image && (
        <div
          className={classNames(styles.image, classes?.image, {
            [styles.image_device]: imageStyle === 'device'
          })}
        >
          {' '}
          <img src={image} alt='new' />
        </div>
      )}
      {label && (
        <label className={classNames(styles.label, classes?.label)}>
          {label}
        </label>
      )}
    </div>
  )
);

export { RadioRedesign };
