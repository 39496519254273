import React from 'react';
import classNames from 'classnames';
import { hoc } from '../../utils';
import { ErrorMessage } from '../error-message';
import { useTextareaProps } from './text-area.props';
import styles from './text-area.module.scss';

/**
 * <Textarea />
 */
const Textarea = hoc(
  useTextareaProps,
  ({
    placeholder,
    className,
    disabled,
    isError,
    error,
    question,
    value,
    focused,
    gridTemplateColumns,
    _onChange,
    _onBlur,
    _onFocus
  }) => (
    <div className={styles.container}>
      <div className={styles.sub_container} style={{ gridTemplateColumns }}>
        <textarea
          className={classNames(className, styles.textarea, {
            [styles.textarea_disabled]: !!disabled,
            [styles.textarea_invalid]: !!isError,
            [styles.textarea_focused]: focused
          })}
          value={value}
          disabled={!!disabled}
          placeholder={placeholder}
          onChange={_onChange}
          onFocus={_onFocus}
          onBlur={_onBlur}
        />
        {question.image && (
          <img
            className={styles.question_image}
            src={question.image}
            alt='Question Image'
          />
        )}
      </div>

      <ErrorMessage error={error} isError={isError} withPadding />
    </div>
  )
);

/**
 * <Textarea /> default props
 */
Textarea.defaultProps = {
  value: ''
};

const TextSmallarea = hoc(
  useTextareaProps,
  ({
    placeholder,
    className,
    disabled,
    isError,
    error,
    question,
    value,
    focused,
    gridTemplateColumns,
    _onChange,
    _onBlur,
    _onFocus
  }) => (
    <div className={styles.container}>
      <div className={styles.sub_container} style={{ gridTemplateColumns }}>
        <textarea
          className={classNames(className, styles.textsmallarea, {
            [styles.textsmallarea_disabled]: !!disabled,
            [styles.textsmallarea_invalid]: !!isError,
            [styles.textsmallarea_focused]: focused
          })}
          value={value}
          disabled={!!disabled}
          placeholder={placeholder}
          onChange={_onChange}
          onFocus={_onFocus}
          onBlur={_onBlur}
        />
        {question.image && (
          <img
            className={styles.question_image}
            src={question.image}
            alt='Question Image'
          />
        )}
      </div>

      <ErrorMessage error={error} isError={isError} withPadding />
    </div>
  )
);

/**
 * <Textarea /> default props
 */
TextSmallarea.defaultProps = {
  value: ''
};

export { Textarea, TextSmallarea };
