import React, { useContext, useEffect } from 'react';
import { ErrorMessage } from 'src/core';
import { useStore } from 'src/context';
import { isValidPMIMail } from 'src/core';
import { EmailPMIQuestionProps } from './email-pmi-question.props';
import styles from './email-pmi-question.module.scss';

const EmailPMI: React.FC<EmailPMIQuestionProps> = ({
  question,
  onChange,
  value,
  isError,
  error
}) => {
  const { changeIsQuestionAnswered } = useStore();

  useEffect(() => {
    changeIsQuestionAnswered(isValidPMIMail(value.toLowerCase()));
  }, [value]);

  return (
    <div>
      <div>
        <input
          className={styles.emailpmi}
          type='text'
          value={value.toLowerCase()}
          onChange={event => onChange?.(question, event.target.value)}
        />
        <ErrorMessage error={error} isError={isError} withPadding />
      </div>
    </div>
  );
};

export { EmailPMI };
