import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Checkbox, Comment, ErrorMessage, hoc, NumberInput } from 'src/core';
import { toCommentCase } from '../../utils';
import { useCheckboxNumberMultipleProps } from './checkbox-number-multiple.props';
import styles from './checkbox-number-multiple.module.scss';

/**
 * <CheckboxNumberMultiple />
 */
const CheckboxNumberMultiple = hoc(
  useCheckboxNumberMultipleProps,
  ({
    variants,
    isNumberError,
    isNumberErrorField,
    onInputChange,
    onCheckboxChange,
    question,
    isError,
    error,
    isLimitSumError,
    limitSumErrorMessage,
    isLimitSellError,
    limitSellErrorKeys,
    limitSellErrorMessage,
    isLimitSellMinError,
    limitSellMinErrorMessage,
    limitSellMinErrorKeys,
    numberErrorMessage,
    numberErrorMessageKeys,
    imageStyle,
    value
  }) =>
    typeof value !== 'string' ? (
      <div>
        {variants.map(({ text, comment, checkbox, imageUrl }, index) => {
          const checked = Object.keys(value).includes(text);
          const commentText = toCommentCase(text);

          let isSellError = false;
          let message = '';

          if (limitSellErrorKeys.includes(text)) {
            isSellError = limitSellErrorKeys.includes(text);
            message = limitSellErrorMessage;
          } else if (limitSellMinErrorKeys.includes(text)) {
            isSellError = limitSellMinErrorKeys.includes(text);
            message = limitSellMinErrorMessage;
          } else if (numberErrorMessageKeys.includes(text)) {
            isSellError = numberErrorMessageKeys.includes(text);
            message = numberErrorMessage;
          }

          return (
            <div
              className={classNames(styles.variant, {
                [styles.variant_with_comment]: comment,
                [styles.variant_one_before_from_last]:
                  variants.some(({ comment }) => comment) &&
                  index === variants.length - 2
              })}
              key={text}
            >
              {!comment && (
                <Fragment>
                  <Checkbox
                    classes={{
                      root: styles.variant_checkbox,
                      flag: styles.variant_flag,
                      image: styles.variant_image
                    }}
                    question={question}
                    label={text}
                    value={checked}
                    onChange={() => onCheckboxChange(text)}
                    isError={checked && !value[text] && isError}
                    image={imageUrl}
                    imageStyle={imageStyle}
                  />
                  {!checkbox && (
                    <NumberInput
                      classes={{ root: styles.variant_input }}
                      name={text}
                      value={value[text] ? value[text] : ''}
                      onChange={onInputChange}
                      disabled={!checked}
                      isError={
                        checked &&
                        (!value[text] ||
                          isSellError ||
                          numberErrorMessageKeys.includes(text))
                      }
                    />
                  )}
                </Fragment>
              )}
              {comment && (
                <div className={styles.comment_field}>
                  <Checkbox
                    classes={{
                      root: styles.variant_checkbox_comment,
                      label: styles.variant_checkbox_label,
                      flag: styles.variant_checkbox_flag
                    }}
                    question={question}
                    label="Інший варіант"
                    value={checked}
                    onChange={() => onCheckboxChange(text)}
                    isError={checked && !value[text] && isError}
                    image={imageUrl}
                    name={commentText}
                  />
                  <Comment
                    classes={{ root: styles.variant_comment }}
                    placeholder={text}
                    name={commentText}
                    value={value[commentText] ?? ''}
                    onChange={onInputChange}
                    disabled={!checked}
                  />
                </div>
              )}
              {isSellError && (
                <ErrorMessage isError={isSellError} error={message} />
              )}

              {isError && checked && !value[text] && (
                <ErrorMessage
                  isError={isError && checked && !value[text]}
                  error="Щоб продовжити опитування, вкажіть кількість девайсів."
                />
              )}
            </div>
          );
        })}

        <ErrorMessage
          isError={
            isLimitSumError ||
            isError ||
            isLimitSellError ||
            isLimitSellMinError
          }
          error={isLimitSumError ? limitSumErrorMessage : error}
        />
      </div>
    ) : null
);

export { CheckboxNumberMultiple };
