import { useHistory } from 'react-router-dom';
import { useFormData, useProgressData } from './hooks';

/**
 * <Provider /> props
 */
const useProviderProps = () => {
  const formData = useFormData();
  const history = useHistory();

  const progressData = useProgressData(
    history?.push,
    formData.id,
    formData.sendAnswers,
    formData.autoclose,
    formData.autocloseMinutes,
    formData.questions,
    formData.results
  );

  return {
    store: {
      ...formData,
      ...progressData
    }
  };
};

export { useProviderProps };
