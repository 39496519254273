import { InputHTMLAttributes, useRef, useState } from 'react';

/**
 * <NumberInput /> props type
 */
type NumberInputProps = InputHTMLAttributes<HTMLInputElement> & {
  /**
   * Classes
   */
  classes?: {
    root?: string;
    input?: string;
  };
  /**
   * Is error
   */
  isError?: boolean;
  /**
   * Error
   */
  error?: string;
};

/**
 * <NumberInput /> props
 */
const useNumberInputProps = ({
  onFocus,
  onBlur
}: Partial<NumberInputProps>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState(false);

  const _onFocus = event => {
    setFocused(true);

    onFocus?.(event);
  };

  const _onBlur = event => {
    setFocused(false);

    onBlur?.(event);
  };

  return {
    inputRef,
    focused,
    _onFocus,
    _onBlur
  };
};

export { useNumberInputProps };
