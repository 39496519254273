import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Comment, ErrorMessage, hoc, RadioRedesign } from 'src/core';
import { getTextFromCheckboxQuestionValue } from '../../utils';
import {
  CheckboxRadioListItemWrapperRedesign,
  CheckboxRadioListWrapperRedesign
} from '../shared';
import { useRadioQuestionRedesignProps } from './radio-question-redesign.props';
import styles from './radio-question-redesign.module.scss';

/**
 * <RadioQuestionRedesign />
 */
const RadioQuestionRedesign = hoc(
  useRadioQuestionRedesignProps,
  ({
    question,
    configuredList,
    value,
    isBigList,
    isError,
    error,
    imageStyle,
    onChange
  }) => (
    <Fragment>
      <CheckboxRadioListWrapperRedesign>
        {configuredList.map(({ text, imageUrl, comment }, index) => {
          const checked = text === getTextFromCheckboxQuestionValue(value);

          return (
            <CheckboxRadioListItemWrapperRedesign
              key={index}
              onCheckboxRadioClick={() =>
                onChange(
                  question,
                  comment ? { answer: text, comment: '' } : text
                )
              }
              isImageUrl={configuredList.some(({ imageUrl }) => imageUrl)}
              commentVal={comment}
              comment={
                <React.Fragment>
                  {comment && (
                    <Comment
                      name={text}
                      disabled={!checked}
                      value={typeof value === 'string' ? '' : value.comment}
                      placeholder='Вкажіть'
                      onChange={event => {
                        const commentValue =
                          typeof value === 'string' ? { answer: text } : value;
                        return onChange(question, {
                          ...commentValue,
                          comment: event.target.value
                        });
                      }}
                    />
                  )}
                </React.Fragment>
              }
            >
              <RadioRedesign
                classes={{
                  root: classNames(isBigList && styles.radio__small_root),
                  flag: classNames(isBigList && styles.radio__small_flag),
                  label: classNames(isBigList && styles.radio__small_font)
                }}
                question={question}
                label={text}
                image={imageUrl}
                value={checked}
                disabled={checked}
                onChange={() => {}}
                isError={isError}
                error={error}
                imageStyle={imageStyle}
              />
            </CheckboxRadioListItemWrapperRedesign>
          );
        })}
      </CheckboxRadioListWrapperRedesign>

      <ErrorMessage error={error} isError={isError} />
    </Fragment>
  )
);

export { RadioQuestionRedesign };
