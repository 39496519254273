import { set } from 'immer/dist/internal';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  FileFormats,
  FormControlProps,
  QuestionModel,
  sendMedia
} from 'src/api';
import { useStore } from 'src/context';

/**
 * <Upload /> props type
 */
type UploadProps = FormControlProps & {
  /**
   * Valid file formats
   */
  accept: FileFormats;
  /**
   * Question
   */
  question: QuestionModel;
  /**
   * OnChange handler
   */
  answers: Array<{
    uploadFileExtensions: string;
    uploadFileExtension_error_text: string;
    uploadSize: string;
    uploadSize_error_text: string;
  }>;
  onChange: (question: QuestionModel, value: string[]) => void;
};

/**
 * <Upload /> props
 */
const useUploadProps = ({
  question,
  value,
  onChange
}: Partial<UploadProps>) => {
  const [fileUrls, setFileUrls] = useState([]);

  const { answers, required } = question || ({} as QuestionModel);

  const handleChangeFileUrls = useCallback(
    (fileUrl: string) => {
      setFileUrls([...fileUrls, fileUrl]);
    },
    [fileUrls]
  );

  useEffect(() => {
    if (required && fileUrls.length === 0) return;

    onChange(question as QuestionModel, fileUrls);
  }, [fileUrls]);

  const { changeIsQuestionAnswered } = useStore();

  useEffect(() => {
    changeIsQuestionAnswered(!!value);

    if (!value) return;
  }, [value, changeIsQuestionAnswered]);

  return {
    answers,
    fileUrls,
    handleChangeFileUrls
  };
};

export { useUploadProps };
