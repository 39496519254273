import React from 'react';
import { ControlDownArrows, ControlUpArrows } from './control-arrows.component';
import styles from './clock.module.scss';
import classNames from 'classnames';

const Clock = ({ hours, minutes, type, handleChange }) => (
  <div className={styles.time_picker_container}>
    <ControlUpArrows
      hours={hours}
      minutes={minutes}
      handleIncrement={handleChange}
    />
    <div
      className={classNames(styles.time_picker, {
        [styles.time_picker_range_width]: type === 'time_range'
      })}
    >
      <p className={styles.time_picker_item}>{`${
        hours < 10 ? '0' : ''
      }${hours}`}</p>
      <span className={styles.time_picker_item}>:</span>
      <p className={styles.time_picker_item}>{`${
        minutes < 10 ? '0' : ''
      }${minutes}`}</p>
    </div>
    <ControlDownArrows
      hours={hours}
      minutes={minutes}
      handleDecrement={handleChange}
    />
  </div>
);

export default Clock;
