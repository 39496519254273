import React from 'react';
import { InputText } from 'src/core';
import { TextRegexpQuestionProps } from './text-regexp-question.props';
import styles from './text-regexp-question.module.scss';

/**
 * <TextRegexpQuestion />
 */
const TextRegexpQuestion: React.FC<TextRegexpQuestionProps> = ({
  ...props
}) => (
  <div className={styles.text_question}>
    <InputText {...props} />
  </div>
);

export { TextRegexpQuestion };
