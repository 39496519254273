import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {
  generateUniqueCookie,
  getCookieByName,
  setCookieByName
} from '../../core';
import { instance, instanceCookie } from './base';
import { v4 as uuidv4 } from 'uuid';

/**
 * Send Corezoid
 */
const sendCorezoid = async (
  body: any,
  latitude: number | undefined,
  longitude: number | undefined
) => {
  await instance.post('/corezoid', {
    ...body,
    latitude,
    longitude
  });
};

/**
 * Get Visitor Data
 */
const getVisitorData = async () => {
  const fp = await FingerprintJS.load();

  const { components, visitorId } = await fp.get();

  return { visitorId, components };
};

function getAllUrlParams(current_url) {
  // get query string from url (optional) or window
  var queryString = current_url
    ? current_url.split('?')[1]
    : window.location.search.slice(1);

  // well store the parameters here
  var obj = {};

  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use true if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesnt exist
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if its an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // were dealing with a string
        if (!obj[paramName]) {
          // if it doesnt exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and its a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }
  return obj;
}

/**
 * Send FingerPrint
 */
const sendFingerPrint = async (phone: string, taxnumber?: number) => {
  const nameOfTheCookieSessionGlobal = 'sessionId';
  const sessionIdOldGlobal = getCookieByName(nameOfTheCookieSessionGlobal);
  const generateUniqueCookieSessionGlobal = () =>
    getCookieByName(nameOfTheCookieSessionGlobal) || uuidv4();
  const sessionIdGlobal = generateUniqueCookieSessionGlobal();

  try {
    if (sessionIdOldGlobal !== sessionIdGlobal) {
      setCookieByName(nameOfTheCookieSessionGlobal, sessionIdGlobal);
    }
  } catch (error) {
    console.log(error);
  }

  const iqos_visitor_id = generateUniqueCookie();

  try {
    await instanceCookie.get(`?iqos_visitor_id=${iqos_visitor_id}`, {
      withCredentials: true
    });
  } catch (error) {
    console.log(error);
  }

  try {
    const { visitorId, components } = await getVisitorData();
    const { data } = await axios.get(
      'https://www.cloudflare.com/cdn-cgi/trace'
    );

    //@ts-ignore
    components.userAgent = navigator.userAgent;

    const details = FingerprintJS.componentsToDebugString(components).replace(
      /\n/g,
      ''
    );

    const current_url = window.location.href.substring(0, 255);
    const newUrl = new URL(current_url);
    const url = newUrl.origin + '' + newUrl.pathname;
    const hash = newUrl.searchParams.get('hash');
    var utms = getAllUrlParams(current_url);

    const body = {
      phone,
      details: components,
      taxnumber: taxnumber || '',
      ip_data: data,
      device_hash: visitorId,
      iqos_visitor_id,
      source: url,
      hash,
      utms,
      reason: 'open survey',
      sessionId: sessionIdGlobal
    };

    navigator.geolocation.getCurrentPosition(
      async location => {
        const { latitude, longitude } = location.coords;

        await sendCorezoid(body, latitude, longitude);
      },
      async () => {
        await sendCorezoid(body, undefined, undefined);
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export { sendFingerPrint };
