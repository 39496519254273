import React from 'react';
import { hoc } from '../../utils';
import { ErrorMessage } from '../error-message';
import styles from './time-picker.module.scss';
import { useTimePickerProps } from './time-picker.props';
import Clock from './components/clock.component';

const TimePicker = hoc(
  useTimePickerProps,
  ({ isError, error, hours, minutes, type, handleChange }) => (
    <div className={styles.container}>
      <div className={styles.sub_container}>
        <Clock
          hours={hours}
          minutes={minutes}
          handleChange={handleChange}
          type={type}
        />
      </div>
      <ErrorMessage error={error} isError={isError} withPadding />
    </div>
  )
);

/**
 * <TimePicker /> default props
 */
TimePicker.defaultProps = {
  value: ''
};

export { TimePicker };
