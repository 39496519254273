import { useCallback, useEffect, useMemo, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import {
  AnswersType,
  QuestionModel,
  sendFingerPrint,
  MonthAnswer
} from 'src/api';
import { navigate } from 'src/core';
import {
  currentYear,
  maxUserAge,
  minUserAge
} from 'src/app/pages/survey/utils';
import { useStore } from 'src/context';

/**
 * <Form /> props type
 */
type FormProps = {
  /**
   * If field error
   */
  isError: boolean;
  /**
   * Next question
   */
  nextQuestion?: {
    /**
     * Text
     */
    text: string;
    /**
     * description
     */
    description?: string;
    /**
     * Next question
     */
    next_question?: string;
  };
  /**
   * Type
   */
  type?: AnswersType;
  /**
   * Input value
   */
  inputValue?: string;
  /**
   * Question
   */
  question?: QuestionModel;
};

/**
 * <Form /> props
 */
const useFormProps = ({
  type,
  isError,
  inputValue,
  nextQuestion,
  question
}: Partial<FormProps>) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [sendClicked, setSendClicked] = useState(false);

  const {
    id,
    results,
    prev,
    next,
    current,
    questions,
    mobileConfirmation,
    emailConfirmation,
    sendAnswers,
    userPhone,
    isQuestionAnswered,
    changeIsQuestionAnswered,
    changeIsQuestionTouched,
    setLastPageTextTest
  } = useStore();
  const paths = ['/phone', '/phone/confirmation', '/', '/summary'];

  const definingStep = paths.some(item =>
    matchPath(item, {
      path: pathname,
      exact: true
    })
  );
  const prevDisabled = definingStep || current === 0;

  const months = [
    'Cічень',
    'Лютий',
    'Березень',
    'Квітень',
    'Травень',
    'Червень',
    'Липень',
    'Серпень',
    'Вересень',
    'Жовтень',
    'Листопад',
    'Грудень'
  ];
  function calculateAge(birthday) {
    // birthday is a date
    var ageDifMs = Date.now() - birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  const { isLessThan18, isGreaterThan65 } = useMemo(() => {
    if (question?.answers?.some(answer => answer.type === 'birth_year')) {
      const currentAnswer = results[
        (question as QuestionModel).questionId
      ] as MonthAnswer;

      const birthYear = +currentAnswer.year;
      const month = currentAnswer.month;

      const isMonthDontPass = months.indexOf(month);
      const date = new Date(birthYear, isMonthDontPass, 1);
      const diffYear = calculateAge(date);

      const isLessThan18 = diffYear < 18;
      const isGreaterThan65 = diffYear > 65;
      return { isLessThan18, isGreaterThan65 };
    }
    return { isLessThan18: false, isGreaterThan65: false };
  }, [question, results[question!?.questionId]]);

  useEffect(() => {
    if (isLessThan18 || isGreaterThan65) {
      setLastPageTextTest(question.answers[0].endQuestionaryText);
    }
  }, [isLessThan18, isGreaterThan65, setLastPageTextTest]);

  const last = useMemo(() => {
    const answerText = results[question!?.questionId];

    if (question?.answers?.some(answer => answer.type === 'birth_year')) {
      return isLessThan18 || isGreaterThan65;
    }

    const answer = question?.answers[0].list?.find(
      item => item?.text === answerText
    );

    return (
      answer?.endQuestionary ||
      question?.endQuestionary ||
      current === questions?.length - 1
    );
  }, [question, results[question!?.questionId], isLessThan18, isGreaterThan65]);

  const sendQuestionsAnswers = useCallback(() => {
    if (mobileConfirmation || emailConfirmation) return;
    sendAnswers();
  }, [mobileConfirmation, emailConfirmation, sendAnswers]);

  const onMobileSendClick = useCallback(() => {
    if (type === 'inn') {
      sendFingerPrint(userPhone!, +inputValue!);
    }
    sendQuestionsAnswers();
  }, [type, userPhone, inputValue, sendQuestionsAnswers]);

  const onNextClick = useCallback(() => {
    changeIsQuestionTouched(true);
    if (question?.required && (isError || !isQuestionAnswered)) return;
    changeIsQuestionAnswered(false);
    changeIsQuestionTouched(false);

    if (type === 'inn') {
      sendFingerPrint(userPhone!, +inputValue!);
    }
    next(nextQuestion, () => navigate(`/summary/?id=${id}`, history.push));
  }, [
    type,
    userPhone,
    inputValue,
    question,
    isError,
    isQuestionAnswered,
    changeIsQuestionTouched,
    changeIsQuestionAnswered,
    next,
    nextQuestion,
    id,
    history
  ]);

  const onPrevClick = () => {
    if (prevDisabled) return;

    changeIsQuestionAnswered(true);

    prev();
  };

  const onSendClick = useCallback(() => {
    onMobileSendClick();
    setSendClicked(true);
  }, [onMobileSendClick]);

  useEffect(() => {
    if (sendClicked) {
      navigate(`/summary/?id=${id}`, history.push);
    }
  }, [sendClicked, id, history]);

  useEffect(() => {
    const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
      sendAnswers(true);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    // window.addEventListener('unload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      // window.removeEventListener('unload', handleBeforeUnload);
    };
  }, [results]);

  return {
    id,
    last,
    mobileConfirmation,
    emailConfirmation,
    isQuestionAnswered,
    onMobileSendClick,
    onNextClick,
    onPrevClick,
    prevDisabled,
    onSendClick
  };
};

export { useFormProps };
