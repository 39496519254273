import * as React from 'react';
import { useState } from 'react';
import { sendNumber } from 'src/api';
import { Button, navigate } from 'src/core';
import classNames from 'classnames';
import { useStore } from 'src/context';
import styles from './email.module.scss';

const Email = (props: any) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState<boolean>(false);
  const { id, setEmail, evt } = useStore();

  /**
   * Handle submit
   */
  const submit = (userEmail: string, evt: string) => {
    setEmail(userEmail);
    sendNumber(userEmail, evt).then((res: any) => {
      const message = res?.data?.message;

      if (message === 'ok') {
        navigate(
          `/email/confirmation/?id=${id}&email=${userEmail}`,
          props.history.push
        );
      }
    });
  };

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onChange = (value: string) => {
    setValue(value);
    if (validateEmail(value)) return setError(true);
    setError(false);
  };

  const submitEmail = (value: string) => {
    validateEmail(value) && submit(value, evt);
  };

  const errorText = error ? '' : 'Невірна email адреса';

  return (
    <div className={styles.phone}>
      <div className={styles.content}>
        <div className={styles.caption}>Залиш, будь ласка, свій email</div>
        <div className={styles.input_container}>
          <input
            className={styles.emailpmi}
            value={value}
            type='text'
            onChange={event => onChange?.(event.target.value.toLowerCase())}
          />
          <div className={styles.error}>{errorText}</div>
          <Button
            className={classNames({
              [styles.button_disabled]: !error
            })}
            onClick={() => submitEmail(value.toLowerCase())}
            rounded
          >
            Далі
          </Button>
        </div>
      </div>
    </div>
  );
};

export { Email };
