import { useEffect } from 'react';
import { AnswerWithComment, List } from 'src/api';
import { useStore } from 'src/context';
import {
  useAgeFilteredList,
  useDependencyListAnswers,
  useDependencyNotShow
} from '../../hooks';
import { QuestionProps } from '../props';

/**
 * <RadioQuestionRedesign /> props type
 */
type RadioQuestionRedesignProps = QuestionProps<AnswerWithComment> & {
  list: List[];
};

/**
 * <RadioQuestionRedesign /> props
 */
const useRadioQuestionRedesignProps = ({
  value,
  ...props
}: Partial<RadioQuestionRedesignProps>) => {
  const { list: filteredList } = useAgeFilteredList(props.list as List[]);
  const imageStyle = props.question?.image_style;

  const dependencyNotShowList = useDependencyNotShow(filteredList);
  const list = useDependencyListAnswers(dependencyNotShowList);
  const isBigList = list.length >= 5;

  const { changeIsQuestionAnswered } = useStore();

  useEffect(() => {
    if (value) {
      changeIsQuestionAnswered(
        typeof value === 'string'
          ? !!value.length
          : !!value?.answer.length && !!value.comment.length
      );
    }
  }, [value]);

  return {
    configuredList: list,
    isBigList,
    imageStyle
  };
};

export { useRadioQuestionRedesignProps };
