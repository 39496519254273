import { FormControlProps, QuestionModel } from 'src/api';

/**
 * <Radio /> props type
 */
type RadioProps = FormControlProps & {
  /**
   * Radio label
   */
  label?: string;
  /**
   * Radio image
   */
  image?: string;
  /**
   * Question
   */
  question?: QuestionModel;
  /**
   * On change handler
   */
  onChange: (question: QuestionModel | null, value: string) => void;
  /**
   * Classes
   */
  classes?: {
    root?: string;
    flag?: string;
    label?: string;
    image?: string;
  };
};

/**
 * <Radio /> props
 */
const useRadioProps = ({
  label,
  question,
  disabled,
  onChange
}: Partial<RadioProps>) => {
  const _onClick = () => {
    if (disabled || !onChange) return;

    onChange(question || null, label || '');
  };

  return { _onClick };
};

export { useRadioProps };
