import moment from 'moment';

const formattingDate = (value: Date | null | string): string =>
  moment(value).format('YYYY-MM-DD');

const formattingTime = (hours: number, minutes: number): string => {
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return `${formattedHours}:${formattedMinutes}`;
};

export { formattingDate, formattingTime };
