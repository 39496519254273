import { instance, sendBeacon } from './base';

/**
 * Submit Survey
 */
const submitSurvey = ({
  survey,
  results,
  isIndividualSurvey,
  userPhone,
  userEmail,
  isClosePage
}: {
  survey: any;
  results: any;
  isIndividualSurvey: boolean;
  userPhone: any;
  userEmail: any;
  isClosePage?: boolean;
}) => {
  survey.template.template.questions.questions = results;
  survey.phone = userPhone;
  survey.email = userEmail;

  /*close page logic not now*/
  // if (isClosePage) {
  //   sendBeacon('questionary', JSON.stringify(survey));
  // } else {
  //   instance.post('/questionary', survey);
  // }

  instance.post('/questionary', survey);

  if (!isIndividualSurvey) return;

  instance.patch(`/survey/${survey.id}`, { completed: true });

  /*close page logic not now*/
  // if (isClosePage) {
  //   sendBeacon(`survey/${survey.id}`, JSON.stringify({ completed: true }));
  // } else {
  //   instance.patch(`/survey/${survey.id}`, { completed: true });
  // }
};

export { submitSurvey };
