import { useState } from 'react';

/**
 * Range Value
 */
type RangeValue = number;

/**
 * <Range /> props type
 */
type RangeProps = {
  answExclusiveFlag?: boolean;
  exclusive?: boolean;
  /**
   * Text
   */
  text: string;
  /**
   * Max
   */
  max: number;
  /**
   * Min
   */
  min: number;
  /**
   * Value
   */
  value: number;
  /**
   * Is Error
   */
  isError: boolean;
  /**
   * Checkbox
   */
  checkbox: any;
  stepPersent?: number;
  isPercentage?: boolean;
  /**
   * On change
   */
  onChange: (value: RangeValue) => void;
};

/**
 * <Range /> props
 */
const useRangeProps = ({
  value,
  checkbox,
  exclusive,
  answExclusiveFlag,
  onChange
}: Partial<RangeProps>) => {
  const isValueNumber = typeof value === 'number';

  const [touched, setTouched] = useState(false);
  const [checked, setChecked] = useState(checkbox ? checkbox === value : false);

  const _onChange = (value: number) => {
    onChange(value);
    if (touched) return;

    setTouched(true);
  };

  const _onCheckboxChange = () => {
    setChecked(!checked);
    checkbox = exclusive ? true : checkbox;
    _onChange(!checked ? checkbox : exclusive ? false : -1);
  };

  return {
    checked,
    touched,
    isValueNumber,
    _onChange,
    _onCheckboxChange
  };
};

export { useRangeProps };
