import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FormControlProps, QuestionModel } from 'src/api';
import { useStore } from 'src/context';

/**
 * <InputText /> props type
 */
type InputTextProps = FormControlProps & {
  /**
   * Question
   */
  question: QuestionModel;
  /**
   * OnChange handler
   */
  onChange: (question: QuestionModel, value: string) => void;
};

/**
 * <InputText /> props
 */
const useInputTextProps = ({
  question,
  value,
  onChange
}: Partial<InputTextProps>) => {
  const { changeIsQuestionAnswered } = useStore();

  const [isRegexpError, setIsRegexpError] = useState(false);
  const [focused, setFocused] = useState(false);

  const { answers } = question || ({} as QuestionModel);
  const { regexp, regexp_error_text: regexpErrorText } =
    answers[0] || ({} as any);

  const gridTemplateColumns = `${question?.image ? '1fr 1fr' : '1fr'}`;

  //'^[a-zA-Z]+$' - regexp for only latin letters
  //'^[А-ЩЬЮЯҐЄІЇа-щьюяґєії']+$' - regexp for only ukrainian letters

  const regexpCheck = useCallback(
    (value: string) => {
      const regexpText = new RegExp(regexp);
      return regexpText.test(value);
    },
    [regexp]
  );

  const _onFocus = () => {
    setFocused(true);
  };

  const _onBlur = () => {
    setFocused(false);
  };

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    const inputValue = event.target.value;
    const regexpIsValid = regexpCheck(inputValue);
    setIsRegexpError(!regexpIsValid);

    onChange(question as QuestionModel, event.target.value);
  };

  useEffect(() => {
    const isAnswered = !!value?.length && !isRegexpError;
    changeIsQuestionAnswered(isAnswered);
  }, [value, isRegexpError, changeIsQuestionAnswered]);

  return {
    focused,
    gridTemplateColumns,
    isRegexpError,
    regexpErrorText,
    _onChange,
    _onFocus,
    _onBlur
  };
};

export { useInputTextProps };
