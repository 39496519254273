import React from 'react';
import { hoc } from '../../utils';
import { ErrorMessage } from '../error-message';
import styles from './date-picker.module.scss';
import { useDatePickerProps } from './date-picker.props';
import Calendar from 'react-calendar';
import { PrevArrow, NextArrow } from './components/arrows.component';

const DatePicker = hoc(
  useDatePickerProps,
  ({ isError, error, value, _onChange }) => (
    <div className={styles.container}>
      <div className={styles.sub_container}>
        <Calendar
          onChange={_onChange}
          value={value || new Date()}
          prevLabel={<PrevArrow />}
          prev2Label={null}
          nextLabel={<NextArrow />}
          next2Label={null}
          className={styles.calendar}
        />
      </div>

      <ErrorMessage error={error} isError={isError} withPadding />
    </div>
  )
);

/**
 * <DatePicker /> default props
 */
DatePicker.defaultProps = {
  value: ''
};

export { DatePicker };
