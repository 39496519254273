import React from 'react';
import { TimeRangeQuestionProps } from './time-range-question.props';
import styles from './time-range-question.module.scss';
import { TimeRangePicker } from 'src/core';

/**
 * <TimeRangeQuestion />
 */
const TimeRangeQuestion: React.FC<TimeRangeQuestionProps> = ({ ...props }) => (
  <div className={styles.time_question}>
    <TimeRangePicker {...props} />
  </div>
);

export { TimeRangeQuestion };
