import { List, MultiListVariantDependency, QuestionModel } from 'src/api';
import {
  useLimitSell,
  useLimitSellMin,
  useLimitSum,
  useDemicalError
} from '../../../hooks';
import { useMultipleQuestion } from '../hooks';
import { ChangeEvent, useState } from 'react';

/**
 * <MultipleNumbersQuestion /> props type
 */
type MultipleDemicalQuestionProps = {
  /**
   * Question
   */
  question: QuestionModel<MultiListVariantDependency>;
  /**
   * Text field list
   */
  list: List[];
  /**
   * Value
   */
  value: string;
  /**
   * On change handler
   */
  onChange: (question: QuestionModel, value: any) => void;
  /**
   * Error
   */
  error?: string;
  /**
   * Is error
   */
  isError?: boolean;
};

/**
 * <MultipleNumbersQuestion /> props
 */
const useMultipleDemicalQuestionProps = ({
  question,
  value,
  onChange
}: Partial<MultipleDemicalQuestionProps>) => {
  const { currentResult, variants, onFieldChange } = useMultipleQuestion({
    question: question as QuestionModel,
    value: value as any,
    onChange: onChange as any
  });

  const {
    isError: isLimitSumError,
    errorMessage: limitSumErrorMessage
  } = useLimitSum(question as QuestionModel);

  const {
    isError: isLimitSellError,
    errorMessage: limitSellErrorMessage,
    keys: limitSellErrorKeys
  } = useLimitSell(question as QuestionModel);

  const {
    isError: isLimitSellMinError,
    errorMessage: limitSellMinErrorMessage,
    keys: limitSellMinErrorKeys
  } = useLimitSellMin(question as QuestionModel);

  const {
    isError: isDemicalError,
    errorMessage: numberErrorMessage,
    keys: demicalErrorKeys
  } = useDemicalError(question as QuestionModel);

  const [isDemicalErrorField, isNumber] = useState(false);

  function onlyDemicls(str) {
    const regex1 = /^[0-9]+([\.,][0-9]+)?$/;
    return regex1.test(str);
  }

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onFieldChange(event.target.name, event.target.value);

    if (!onlyDemicls(event.target.value)) {
      isNumber(true);
    } else {
      isNumber(false);
    }
  };

  return {
    variants,
    limitSellErrorKeys,
    limitSellErrorMessage,
    limitSellMinErrorKeys,
    limitSellMinErrorMessage,
    currentResult,
    isLimitSumError,
    limitSumErrorMessage,
    isLimitSellError,
    isLimitSellMinError,
    isDemicalError,
    isDemicalErrorField,
    demicalErrorKeys,
    _onChange
  };
};

export { useMultipleDemicalQuestionProps };
