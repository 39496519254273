import { FormControlProps, QuestionModel } from 'src/api';

/**
 * <Checkbox /> props type
 */
type CheckboxProps = FormControlProps<boolean> & {
  /**
   * Checkbox label
   */
  label: string;
  /**
   * Checkbox image
   */
  image?: string;
  imageStyle?: string;
  /**
   * Question
   */
  question: QuestionModel;
  /**
   * On change handler
   */
  onChange: (question: QuestionModel, value: string) => void;
  /**
   * Classes
   */
  classes?: {
    flag?: string;
    label?: string;
    root?: string;
    image?: string;
  };
};

/**
 * <Checkbox /> props
 */
const useCheckboxProps = ({
  question,
  label,
  disabled,
  onChange
}: Partial<CheckboxProps>) => {
  const _onClick = () => {
    if (disabled) return;

    if (!onChange) return;

    onChange(question as QuestionModel, label as string);
  };

  return { _onClick };
};

export { useCheckboxProps };
