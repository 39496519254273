import React from 'react';
import { hoc } from '../../utils';
import { ErrorMessage } from '../error-message';
import styles from './time-range-picker.module.scss';
import { useTimeRangePickerProps } from './time-range-picker.props';
import Clock from '../time-picker/components/clock.component';

const TimeRangePicker = hoc(
  useTimeRangePickerProps,
  ({
    isError,
    error,
    hoursStart,
    minutesStart,
    hoursEnd,
    minutesEnd,
    type,
    onChangeStart,
    onChangeEnd
  }) => {
    const pickers: string[] = ['start', 'end'];

    return (
      <div className={styles.container}>
        <div className={styles.sub_container}>
          {pickers.map((picker, index) => (
            <Clock
              key={index}
              hours={picker === 'start' ? hoursStart : hoursEnd}
              minutes={picker === 'start' ? minutesStart : minutesEnd}
              handleChange={picker === 'start' ? onChangeStart : onChangeEnd}
              type={type}
            />
          ))}
        </div>
        <ErrorMessage error={error} isError={isError} withPadding />
      </div>
    );
  }
);

/**
 * <TimePickerComponent /> default props
 */
TimeRangePicker.defaultProps = {
  value: ''
};

export { TimeRangePicker };
