import { useEffect } from 'react';
import { navigate, redirectUrl } from '../utils';

/**
 * Use Redirect
 */
const useRedirect = () => {
  useEffect(() => {
    const isIqos = window.location.origin.includes('.iqos.com.ua');
    if (isIqos) {
      const timer = setTimeout(() => {
        navigate(redirectUrl);
      }, 250000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, []);
};

export { useRedirect };
