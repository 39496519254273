function removeDuplicateCommentOptions(obj: {
  [key: string]: any;
}): { [key: string]: any } {
  let keysToRemove = new Set<string>();

  // First pass: Identify the keys to remove
  for (let key in obj) {
    if (key.startsWith('comment-')) {
      let originalKey = key.replace('comment-', '');
      if (obj.hasOwnProperty(originalKey)) {
        keysToRemove.add(originalKey);
      }
    }
  }

  // Second pass: Create the new object without the keys to remove
  let result: { [key: string]: any } = {};
  for (let key in obj) {
    if (!keysToRemove.has(key)) {
      result[key] = obj[key];
    }
  }

  return result;
}

export { removeDuplicateCommentOptions };
