import { Dependency, ListVariantDependency } from 'src/api';
import { isAllKeysExists, isExists } from '../check-value';

/**
 * Is List Variant Dependency Not Show
 */
const isListVariantDependencySecond = (
  dependency_second: Dependency | undefined
): dependency_second is ListVariantDependency =>
  isExists(dependency_second) &&
  isAllKeysExists<Dependency, keyof ListVariantDependency>(dependency_second, [
    'answers'
  ]);

export { isListVariantDependencySecond };
