import React from 'react';
import { hoc } from 'src/core';
import { useSummaryProps } from './summary-autoclose.props';
import styles from './summary-autoclose.module.scss';

/**
 * <Summary />
 */
const SummaryAutoclose = hoc(useSummaryProps, ({ autocloseMessage }) => (
  <div className={styles.summary}>
    <div className={styles.content}>
      <div className={styles.caption}>
        {autocloseMessage ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${autocloseMessage}`
            }}
          />
        ) : (
          'Перепрошуємо, але ти не зможеш взяти участь у цьому опитуванні згідно правил проведення маркетингових досліджень.'
        )}
      </div>
    </div>
  </div>
));

export { SummaryAutoclose };
