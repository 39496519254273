import axios from 'axios';
import http from 'http';
import https from 'https';

/**
 * Is Prod
 */
const isProd = window.location.origin.includes('.iqos.com.ua')
  ? true
  : window.location.origin.includes('survey-ua.com')
  ? true
  : false;

/**
 * Api Urls
 */
const apiUrls = {
  development: 'https://qkod5wqr2b.execute-api.eu-central-1.amazonaws.com/DEV',
  production: 'https://qkod5wqr2b.execute-api.eu-central-1.amazonaws.com/PROD'
};

/**
 * Api Url
 */
const apiUrl = isProd ? apiUrls.production : apiUrls.development;

/**
 * Cookie Api Url
 */
const cookieApiUrl = 'https://ss.iqos.com.ua/visitor-id-cookie';

/**
 * Tokens
 */
const tokens = {
  development: 'dD3kjt34s&dhk48Yh8',
  production: '1f5kjtf4vcfhk72ff1'
};

/**
 * Token
 */
const token = isProd ? tokens.production : tokens.development;

/**
 * Instance
 */
const instance = axios.create({
  baseURL: apiUrl,
  headers: { token }
});

const sendBeacon = (url: string, data: string) => {
  fetch(`${apiUrl}/${url}`, {
    method: 'POST',
    headers: { token },
    body: data,
    keepalive: true
  });
  // navigator.sendBeacon(`${apiUrl}/${url}`, payload);
};

/**
 * Instance Cookie
 */
const instanceCookie = axios.create({
  baseURL: cookieApiUrl
});

export { instance, instanceCookie, sendBeacon };
