import { isStringedNumber } from '../../utils';

/**
 * Is More Than Zero
 */
const isMoreThanZero = (text: string | number) => +text > 0;

/**
 * Is Not Empty
 */
const isNotEmpty = (text: string) => !!text.length;

/**
 * Is Answered
 */
const isAnswered = (text: string) =>
  isStringedNumber(text) ? isMoreThanZero(text) : isNotEmpty(text);

/**
 * Calculate Total Percentage
 */
function calculateTotalPercentage(values: { [key: string]: string }) {
  return Object.values(values).reduce(
    (total, value) => total + (parseInt(value) || 0),
    0
  );
}

export { isMoreThanZero, isNotEmpty, isAnswered, calculateTotalPercentage };
