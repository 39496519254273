import { useEffect, useState } from 'react';
import { FormControlProps, QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import { formattingTime } from 'src/core/utils';

/**
 * <TimePicker /> props type
 */
type TimePickerProps = FormControlProps & {
  /**
   * Question
   */
  question: QuestionModel;
  /**
   * OnChange handler
   */
  onChange: (question: QuestionModel, value: string) => void;
};

/**
 * <TimePicker /> props
 */
const useTimePickerProps = ({
  question,
  value,
  onChange
}: Partial<TimePickerProps>) => {
  const { changeIsQuestionAnswered } = useStore();

  const currentHours = new Date().getHours();
  const currentMinutes = new Date().getMinutes();

  const [hours, setHours] = useState<number>(currentHours);
  const [minutes, setMinutes] = useState<number>(currentMinutes);

  const { answers } = question;
  const { type } = answers[0];

  const handleChange = (
    type: 'hoursUp' | 'minutesUp' | 'hoursDown' | 'minutesDown'
  ) => {
    const actions = {
      hoursUp: () => setHours(prevHours => (prevHours + 1) % 24),
      minutesUp: () => setMinutes(prevMinutes => (prevMinutes + 1) % 60),
      hoursDown: () => setHours(prevHours => (prevHours - 1 + 24) % 24),
      minutesDown: () => setMinutes(prevMinutes => (prevMinutes - 1 + 60) % 60)
    };

    actions[type]?.();
  };

  useEffect(() => {
    const newTime = formattingTime(hours, minutes);
    onChange(question as QuestionModel, newTime);
  }, [hours, minutes]);

  useEffect(() => {
    changeIsQuestionAnswered(!!value);
  }, [value, changeIsQuestionAnswered]);

  return {
    hours,
    minutes,
    type,
    handleChange
  };
};

export { useTimePickerProps };
