import React from 'react';
import leftArrow from '../../../../assets/images/arrow-left-calendar.svg';
import styles from './control-arrows.module.scss';

const ControlUpArrows = ({ hours, minutes, handleIncrement }) => (
  <div className={styles.arrow_container}>
    <button
      type='button'
      name='upHour'
      className={styles.arrow_buttons}
      onClick={() => handleIncrement('hoursUp')}
      disabled={hours > 23}
    >
      <img
        src={leftArrow}
        alt='Hour arrow'
        style={{ transform: 'rotate(90deg)' }}
      />
    </button>
    <button
      type='button'
      name='upMinute'
      className={styles.arrow_buttons}
      onClick={() => handleIncrement('minutesUp')}
      disabled={minutes > 59}
    >
      <img
        src={leftArrow}
        alt='Minute arrow'
        style={{ transform: 'rotate(90deg)' }}
      />
    </button>
  </div>
);

const ControlDownArrows = ({ hours, minutes, handleDecrement }) => (
  <div className={styles.arrow_container}>
    <button
      type='button'
      name='downHour'
      className={styles.arrow_buttons}
      onClick={() => handleDecrement('hoursDown')}
      disabled={hours < 0}
    >
      <img
        src={leftArrow}
        alt='Hour arrow'
        style={{ transform: 'rotate(270deg)' }}
      />
    </button>
    <button
      type='button'
      name='downMinute'
      className={styles.arrow_buttons}
      onClick={() => handleDecrement('minutesDown')}
      disabled={minutes < 0}
    >
      <img
        src={leftArrow}
        alt='Minute arrow'
        style={{ transform: 'rotate(270deg)' }}
      />
    </button>
  </div>
);
export { ControlUpArrows, ControlDownArrows };
