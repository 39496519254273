import { QuestionModel } from 'src/api';
import { useValueAsObject } from './use-value-as-object.hook';
import { useCurrentAnswer } from './use-current-answer.hook';
import { isIncludeCommentCase } from '../utils';

/**
 * Use Limit Sell
 */
const useLimitElementsMax = (question: QuestionModel) => {
  const { getAnswer } = useCurrentAnswer();

  const maxElements = question.max_elements ?? Infinity;

  const currentResult = useValueAsObject<string | number | any>(
    getAnswer(question.questionId)
  );

  const curentElements = Object.entries(currentResult).length;

  return {
    isError: curentElements > maxElements,
    errorMessage: 'Перевищено ліміт обраних елементів'
  };
};

export { useLimitElementsMax };
