import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

/**
 * Name of the cookie
 */
const nameOfTheCookie = 'iqos_visitor_id';

/**
 * Get cookie by name
 */
const getCookieByName = (name: string) => {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  );

  return !!matches ? decodeURIComponent(matches[1]) : undefined;
};

const setCookieByName = (name: string, value: string) => {
  Cookies.set(name, value, { path: '', domain: '.iqos.com.ua' });
  //document.cookie = name + "=" + (value || "; Path=/; Domain=.veeba.com.ua;");

  return true;
};

/**
 * Get unique cookie to send to API
 */
const generateUniqueCookie = () => getCookieByName(nameOfTheCookie) || uuidv4();

export { generateUniqueCookie, getCookieByName, setCookieByName };
