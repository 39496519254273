import React from 'react';
import { DatePicker } from 'src/core';
import { DateQuestionProps } from './date-question.props';
import styles from './date-question.module.scss';

/**
 * <DateQuestion />
 */
const DateQuestion: React.FC<DateQuestionProps> = ({ ...props }) => (
  <div className={styles.date_question}>
    <DatePicker {...props} />
  </div>
);

export { DateQuestion };
