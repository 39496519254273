import React from 'react';
import { hoc } from 'src/core';
import { useSummaryProps } from './summary.props';
import styles from './summary.module.scss';

/**
 * <Summary />
 */
const Summary = hoc(useSummaryProps, ({ caption, lastPageText }) => (
  <div className={styles.summary}>
    <div className={styles.content}>
      <div className={styles.caption}>
        {caption ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${lastPageText.caption} <span class='${styles.heart}' role='img' aria-label='heart'>❤️</span>`
            }}
          />
        ) : (
          'Перепрошуємо, але ти не зможеш взяти участь у цьому опитуванні згідно правил проведення маркетингових досліджень.'
        )}
      </div>
    </div>
  </div>
));

export { Summary };
