import { List, MultiListVariantDependency, QuestionModel } from 'src/api';
import {
  useLimitSell,
  useLimitSellMin,
  useLimitSum,
  useNumberError
} from '../../../hooks';
import { useMultipleQuestion } from '../hooks';
import { ChangeEvent, useState } from 'react';
import { isOnlyNumbers } from '../../../utils';

/**
 * <MultipleNumbersQuestion /> props type
 */
type MultipleNumbersQuestionProps = {
  /**
   * Question
   */
  question: QuestionModel<MultiListVariantDependency>;
  /**
   * Text field list
   */
  list: List[];
  /**
   * Value
   */
  value: string;
  /**
   * On change handler
   */
  onChange: (question: QuestionModel, value: any) => void;
  /**
   * Error
   */
  error?: string;
  /**
   * Is error
   */
  isError?: boolean;
};

/**
 * <MultipleNumbersQuestion /> props
 */
const useMultipleNumbersQuestionProps = ({
  question,
  value,
  onChange
}: Partial<MultipleNumbersQuestionProps>) => {
  const { currentResult, variants, onFieldChange } = useMultipleQuestion({
    question: question as QuestionModel,
    value: value as any,
    onChange: onChange as any
  });

  const {
    isError: isLimitSumError,
    errorMessage: limitSumErrorMessage
  } = useLimitSum(question as QuestionModel);

  const {
    isError: isLimitSellError,
    errorMessage: limitSellErrorMessage,
    keys: limitSellErrorKeys
  } = useLimitSell(question as QuestionModel);

  const {
    isError: isLimitSellMinError,
    errorMessage: limitSellMinErrorMessage,
    keys: limitSellMinErrorKeys
  } = useLimitSellMin(question as QuestionModel);

  const {
    isError: isNumberError,
    errorMessage: numberErrorMessage,
    keys: numberErrorMessageKeys
  } = useNumberError(question as QuestionModel);

  const isQuestionError =
    isLimitSumError || isLimitSellError || isLimitSellMinError || isNumberError;

  const [isNumberErrorField, isNumber] = useState(false);

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onFieldChange(event.target.name, event.target.value);
    if (!isOnlyNumbers(event.target.value)) {
      isNumber(true);
    } else {
      isNumber(false);
    }
  };

  return {
    variants,
    limitSellErrorKeys,
    limitSellErrorMessage,
    limitSellMinErrorKeys,
    limitSellMinErrorMessage,
    numberErrorMessage,
    currentResult,
    isLimitSumError,
    limitSumErrorMessage,
    isLimitSellError,
    isLimitSellMinError,
    isNumberError,
    isNumberErrorField,
    numberErrorMessageKeys,
    isQuestionError,
    _onChange
  };
};

export { useMultipleNumbersQuestionProps };
