import React from 'react';
import classNames from 'classnames';
import { hoc } from '../../utils';
import { ErrorMessage } from '../error-message';
import styles from './input-text.module.scss';
import { useInputTextProps } from './input-text.props';

const InputText = hoc(
  useInputTextProps,
  ({
    placeholder,
    className,
    disabled,
    isError,
    error,
    question,
    value,
    focused,
    gridTemplateColumns,
    isRegexpError,
    regexpErrorText,
    _onChange,
    _onBlur,
    _onFocus
  }) => {
    isError = isRegexpError;
    error = regexpErrorText;

    if (value.length === 0) {
      isError = false;
      error = '';
    }

    return (
      <div className={styles.container}>
        <div
          style={{ gridTemplateColumns }}
          className={classNames(styles.sub_container, {
            [styles.sub_container_invalid]: !!isError
          })}
        >
          <input
            type='text'
            className={classNames(className, styles.input_text, {
              [styles.input_text_disabled]: !!disabled,
              [styles.input_text_invalid]: !!isError,
              [styles.input_text_focused]: focused
            })}
            value={value}
            disabled={!!disabled}
            placeholder={placeholder}
            onChange={_onChange}
            onFocus={_onFocus}
            onBlur={_onBlur}
          />
          {question.image && (
            <img
              className={styles.question_image}
              src={question.image}
              alt='Question Image'
            />
          )}
        </div>

        <ErrorMessage
          error={error}
          isError={isError}
          className={styles.input_error_message}
        />
      </div>
    );
  }
);

/**
 * <InputText /> default props
 */
InputText.defaultProps = {
  value: ''
};

export { InputText };
