import { ChangeEvent, useEffect, useState } from 'react';
import { ListVariant, QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import { commentCasePrefix } from '../../utils/constants';
import {
  useDependencyNotShow,
  useValueAsObject,
  useNumberError,
  usePercentNumbersError,
  useDependencyListVariantAnswers
} from '../../hooks';
import {
  toCommentCase,
  isOnlyNumbersFrom1Till99,
  isOnlyNumbersFrom1till100
} from '../../utils';
import {
  PercentMultipleProps,
  PercentMultipleValue
} from './percent-multiple.type';
import { isAnswered, calculateTotalPercentage } from './percent-multiple.utils';
import { useHistory, useLocation } from 'react-router-dom';
import { navigate } from 'src/core';

/**
 * <PercentMultiple /> props
 */
const usePercentMultipleProps = (props: Partial<PercentMultipleProps>) => {
  const history = useHistory();
  const [isNumberErrorField, setIsNumberError] = useState(false);
  const [isPercentSumError, setIsPercentSumError] = useState(false);
  const answer = (props.question as QuestionModel)?.answers?.[0];

  const {
    id,
    results,
    next,
    changeIsQuestionAnswered,
    changeIsQuestionTouched
  } = useStore();

  const variants = useDependencyNotShow<ListVariant>(
    props.question?.answers[0].list_variant
  );
  const errorText = props.question?.answers[0].error_text;
  const required = props.question?.required;

  const filteredVariants = useDependencyListVariantAnswers(variants);
  const numbersOfVariants = filteredVariants.length;

  const currentValue = useValueAsObject(props.value, () => {
    const newValue =
      typeof props.value === 'string'
        ? filteredVariants
            .filter(({ checked }) => checked)
            .reduce((acc, { text, comment }) => {
              const base = {
                ...acc,
                [text]: ''
              };

              return comment ? { ...base, [toCommentCase(text)]: '' } : base;
            }, {} as PercentMultipleValue)
        : props.value;
    props.onChange?.(props.question as QuestionModel, newValue as any);
  });

  const {
    isError: isNumberError
    // errorMessage: numberErrorMessage,
    // keys: numberErrorMessageKeys
  } = useNumberError(props.question as QuestionModel);

  const {
    isError: isPercentNumbersError,
    errorMessage: percentNumberErrorMessage,
    keys: percentNumberErrorMessageKeys
  } = usePercentNumbersError(
    props.question as QuestionModel,
    numbersOfVariants
  );

  function onInputChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { value, name } = event.target;

    const totalPercentage = calculateTotalPercentage({
      ...currentValue,
      [name]: value
    });

    const isValidNumbers =
      numbersOfVariants === 1
        ? isOnlyNumbersFrom1till100(value)
        : isOnlyNumbersFrom1Till99(value);
    setIsNumberError(!isValidNumbers);

    if (isValidNumbers) {
      setIsPercentSumError(totalPercentage !== 100);
    }

    props.onChange?.(props.question as QuestionModel, {
      ...currentValue,
      [name]: value
    });
  }

  useEffect(() => {
    const asArray = Object.entries(currentValue);
    const filtered = asArray.filter(
      ([key, value]) => !(key.includes(commentCasePrefix) && value === '')
    );
    const values = Object.fromEntries(filtered);
    const filteredVariantsLength = filteredVariants.filter(
      ({ comment }) => !comment
    ).length;
    required
      ? changeIsQuestionAnswered(
          !isNumberError &&
            !isPercentSumError &&
            !isPercentNumbersError &&
            !!Object.keys(values).length &&
            Object.values(values).every(isAnswered) &&
            filteredVariantsLength <= Object.values(values).length
        )
      : changeIsQuestionAnswered(true);
  }, [currentValue]);

  useEffect(() => {
    if (numbersOfVariants === 1) {
      props.onChange?.(props.question as QuestionModel, {
        ...currentValue,
        [filteredVariants[0].text]: 100
      });

      changeIsQuestionTouched(true);
      changeIsQuestionAnswered(false);
      changeIsQuestionTouched(false);
      next(
        answer?.list.find(
          item =>
            (item.value ? item.value.toString() : item.text) ===
            results[(props.question as QuestionModel).questionId]
        ),
        () => navigate(`/summary/?id=${id}`, history.push)
      );

      return;
    }
  }, []);

  return {
    filteredVariants,
    onInputChange,
    isNumberError,
    isNumberErrorField,
    errorText,
    isPercentSumError,
    percentNumberErrorMessage,
    percentNumberErrorMessageKeys,
    numbersOfVariants,
    currentValue,
    ...props
  };
};

export { usePercentMultipleProps };
