import React, { FC } from 'react';
import styles from './checkbox-radio-list-wrapper-redesign.module.scss';

/**
 * <CheckboxRadioListWrapperRedesign />
 */
const CheckboxRadioListWrapperRedesign: FC = ({ children }) => (
  <ul className={styles.wrapper}>{children}</ul>
);

export { CheckboxRadioListWrapperRedesign };
