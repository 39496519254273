import React from 'react';
import classNames from 'classnames';
import { hoc } from '../../utils';
import { ErrorMessage } from '../error-message';
import { useDemicalInputProps } from './demical-input.props';
import styles from './demical-input.module.scss';

/**
 * <DemicalInput />
 */
const DemicalInput = hoc(
  useDemicalInputProps,
  ({
    value,
    inputRef,
    classes,
    error,
    isError,
    focused,
    _onBlur,
    _onFocus,
    ...props
  }) => (
    <div className={classNames(classes?.root)}>
      <input
        type='text'
        inputMode='decimal'
        value={value}
        pattern='[0-9]+([\.,][0-9]+)?'
        ref={inputRef}
        className={classNames(styles.input, {
          [styles.focused]: focused,
          [styles.error]: isError
        })}
        onFocus={_onFocus}
        onBlur={_onBlur}
        {...props}
      />

      {error && <ErrorMessage error={error} isError={isError} />}
    </div>
  )
);

export { DemicalInput };
