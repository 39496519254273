import { QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import { isIncludeCommentCase, removeDuplicateCommentOptions } from '../utils';

/**
 * Use Limit Sum
 */

const useDemicalError = (question: QuestionModel) => {
  const { results } = useStore();

  const currentValue = (results[question.questionId] || {}) as Record<
    string,
    string
  >;
  function onlyDemicls(str) {
    const regex1 = /^[0-9]+([\.,][0-9]+)?$/;
    return regex1.test(str);
  }

  const keys = Object.entries(currentValue)
    .filter(([key]) => key)
    .filter(([, value]) => !onlyDemicls(value))
    .map(([key]) => (key.indexOf('comment-') > -1 ? null : key));

  return {
    isError: Object.values(keys).some(current => current != null),
    keys: keys,
    errorMessage: 'Тільки чисельне значення'
  };
};

const useNumberError = (question: QuestionModel) => {
  const { results } = useStore();

  const currentValue = (results[question.questionId] || {}) as Record<
    string,
    string
  >;

  const filteredCurrentValue = removeDuplicateCommentOptions(currentValue);

  function onlyNumbers(str: string) {
    const regex1 = /^[0-9]+$/;
    return regex1.test(str);
  }

  const keys = Object.entries(filteredCurrentValue)
    .filter(
      ([key, value]) =>
        key && !key.startsWith('comment-') && !onlyNumbers(value)
    )
    .map(([key]) => key);

  return {
    isError: keys.length > 0,
    keys,
    errorMessage: 'Тільки ціле значення'
  };
};

const usePercentNumbersError = (question: QuestionModel, numbersOfVariants) => {
  const { results } = useStore();

  const currentValue = (results[question.questionId] || {}) as Record<
    string,
    string
  >;

  function onlyNumbers(str) {
    const regex1 =
      numbersOfVariants === 1
        ? /^(100|[1-9][0-9]?|0[1-9]|100)$/
        : /^(?:[1-9][0-9]?|99)$/;
    return regex1.test(str);
  }

  const keys = Object.entries(currentValue)
    .filter(([key]) => key)
    .filter(([, value]) => !onlyNumbers(value))
    .map(([key]) => (key.indexOf('comment-') > -1 ? null : key));

  return {
    isError: Object.values(keys).some(current => current != null),
    keys: keys,
    errorMessage:
      numbersOfVariants === 1
        ? 'Значення тільки від 1 до 100'
        : 'Значення тільки від 1 до 99'
  };
};
const useLimitSum = (question: QuestionModel) => {
  const { results } = useStore();

  const limitSum =
    question.limit_sum ?? question.answers[0].limit_sum ?? Infinity;

  const currentValue = (results[question.questionId] || {}) as Record<
    string,
    string
  >;

  const valueSum = Object.entries(currentValue)
    .filter(([key]) => !isIncludeCommentCase(key))
    .reduce((sum, [, current]) => sum + +current, 0);

  return {
    isError: limitSum < valueSum,
    errorMessage: 'Надто велике сумарне значення'
  };
};

export { useLimitSum, useNumberError, useDemicalError, usePercentNumbersError };
