import React from 'react';
import { Header, hoc } from 'src/core';
import { useNotFoundProps } from './not-found.props';
import styles from './not-found.module.scss';

/**
 * <NotFound />
 */
const NotFound = hoc(useNotFoundProps, ({ text }) => (
  <div className={styles.container}>
    <Header />
    <div className={styles.not_found}>
      {text ? (
        <div
          dangerouslySetInnerHTML={{
            __html: `<p class='${styles.captionsmall}'>${text}</p>`
          }}
        />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: `<p class='${styles.caption}'>Сторінку не знайдено</p>`
          }}
        />
      )}
    </div>
  </div>
));

export { NotFound };
