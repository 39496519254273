import classNames from 'classnames';
import { Comment, ErrorMessage, hoc, NumberInput } from 'src/core';
import { toCommentCase } from '../../utils';
import { usePercentMultipleProps } from './percent-multiple.props';
import styles from './percent-multiple.module.scss';

/**
 * <PercentMultiple />
 */
const PercentMultiple = hoc(
  usePercentMultipleProps,
  ({
    filteredVariants,
    isNumberError,
    isNumberErrorField,
    onInputChange,
    isError,
    error,
    errorText,
    isPercentSumError,
    percentNumberErrorMessage,
    percentNumberErrorMessageKeys,
    numbersOfVariants,
    value
  }) =>
    typeof value !== 'string' ? (
      <div>
        {filteredVariants.map(({ text, comment, checkbox }, index) => {
          const commentText = toCommentCase(text);

          let isSellError = false;
          let message = '';

          if (percentNumberErrorMessageKeys.includes(text)) {
            isSellError = percentNumberErrorMessageKeys.includes(text);
            message = percentNumberErrorMessage;
          }

          function inputValue() {
            if (value[text]) {
              return value[text];
            } else if (numbersOfVariants === 1) {
              return 100;
            } else return '';
          }
          const inputData = inputValue();

          return (
            <div
              className={classNames(styles.variant, {
                [styles.variant_with_comment]: comment,
                [styles.variant_one_before_from_last]:
                  filteredVariants.some(({ comment }) => comment) &&
                  index === filteredVariants.length - 2
              })}
              key={text}
            >
              {!comment && (
                <div className={styles.question}>
                  <div className={styles.label}>{text}</div>
                  {!checkbox && (
                    <NumberInput
                      classes={{ root: styles.variant_input }}
                      name={text}
                      value={inputData}
                      disabled={numbersOfVariants === 1}
                      onChange={onInputChange}
                      isError={
                        isNumberErrorField && (!value[text] || isSellError)
                      }
                    />
                  )}
                </div>
              )}
              {comment && (
                <Comment
                  classes={{ root: styles.variant_comment }}
                  placeholder={text}
                  name={commentText}
                  value={value[commentText] ?? ''}
                  onChange={onInputChange}
                />
              )}
              {isSellError && (
                <ErrorMessage isError={isSellError} error={message} />
              )}
            </div>
          );
        })}

        <ErrorMessage
          isError={isError || isPercentSumError || isNumberError}
          error={isPercentSumError ? errorText : error}
        />
      </div>
    ) : null
);

export { PercentMultiple };
