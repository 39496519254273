import React, { Fragment } from 'react';
import { hoc } from '../../utils';
import { useUploadProps } from './upload.props';
import FileInput from './components/file-input.component';

/**
 * <Upload />
 */
const Upload = hoc(
  useUploadProps,
  ({ accept, answers, fileUrls, handleChangeFileUrls }) => (
    <Fragment>
      {Array.from({ length: fileUrls?.length + 1 }).map((_, index) => (
        <FileInput
          key={index}
          accept={accept}
          answers={answers}
          handleChangeFileUrls={handleChangeFileUrls}
        />
      ))}
    </Fragment>
  )
);

export { Upload };
