import React, { FC } from 'react';
import { CheckboxRadioListItemWrapperProps } from './checkbox-radio-list-item-wrapper-redesign.props';
import styles from './checkbox-radio-list-item-wrapper-redesign.module.scss';
import classNames from 'classnames';

/**
 * <CheckboxRadioListItemWrapperRedesign />
 */
const CheckboxRadioListItemWrapperRedesign: FC<CheckboxRadioListItemWrapperProps> = ({
  comment,
  isImageUrl,
  commentVal,
  onCheckboxRadioClick,
  children
}) => (
  <li
    className={classNames(styles.item, {
      [styles.item_with_comment]: commentVal,
      [styles.item_with_image]: isImageUrl
    })}
  >
    <div className={styles.control} onClick={onCheckboxRadioClick}>
      {children}
    </div>
    {comment}
  </li>
);

export { CheckboxRadioListItemWrapperRedesign };
