import { CurrentAnswer } from 'src/api';
import { useStore } from 'src/context';
import {
  getTextFromCheckboxQuestionValue,
  isAnswerComment,
  isStringedNumber
} from '../utils';

/**
 * Get answer
 */
const getAnswer = (questionId: string, results: any) => {
  const currentAnswer = results[questionId] as CurrentAnswer;

  if (Array.isArray(currentAnswer)) {
    return currentAnswer;
  }

  if (isAnswerComment(currentAnswer)) {
    return getTextFromCheckboxQuestionValue(currentAnswer).toString();
  }

  if (typeof currentAnswer === 'string') {
    return currentAnswer;
  }

  if (Object.values(currentAnswer).some(isAnswerComment)) {
    return Object.fromEntries(
      Object.entries(currentAnswer).map(([key, value]) => {
        const filtered = getTextFromCheckboxQuestionValue(value).toString();
        const formatted = isStringedNumber(filtered)
          ? Number(filtered)
          : filtered;

        return [key, formatted];
      })
    );
  }

  return currentAnswer as Record<string, string | number>;
};

/**
 * Use Current Answer
 */
const useCurrentAnswer = () => {
  const { results } = useStore();

  const _getAnswer = (questionId: string) => getAnswer(questionId, results);

  return { getAnswer: _getAnswer };
};

export { useCurrentAnswer, getAnswer };
