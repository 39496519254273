import { QuestionModel } from 'src/api';
import { useValueAsObject } from './use-value-as-object.hook';
import { useCurrentAnswer } from './use-current-answer.hook';
import { isIncludeCommentCase } from '../utils';

/**
 * Use Limit Sell
 */
const useLimitSell = (question: QuestionModel) => {
  const { getAnswer } = useCurrentAnswer();

  const limit =
    question.limit_sell ?? question.answers[0].limit_sell ?? Infinity;

  const currentResult = useValueAsObject<string | number | any>(
    getAnswer(question.questionId)
  );

  const keys = Object.entries(currentResult)
    .filter(([key]) => !isIncludeCommentCase(key))
    .filter(([, value]) => +value > limit)
    .map(([key]) => key);

  return {
    isError: !!keys.length,
    keys: keys,
    errorMessage: 'Надто велике значення'
  };
};

export { useLimitSell };
