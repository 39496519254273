import React from 'react';
import { TimeQuestionProps } from './time-question.props';
import styles from './time-question.module.scss';
import { TimePicker } from 'src/core';

/**
 * <TimeQuestion />
 */
const TimeQuestion: React.FC<TimeQuestionProps> = ({ ...props }) => (
  <div className={styles.time_question}>
    <TimePicker {...props} />
  </div>
);

export { TimeQuestion };
