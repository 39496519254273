import React from 'react';
import { ReactComponent as Logo } from '../../../assets/images/logo-sm.svg';
import styles from './header.module.scss';

/**
 * <Header />
 */
const Header = () => (
  <div></div>
  /*<div className={styles.header}>
    <Logo />
  </div>*/
);

export { Header };
