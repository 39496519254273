/**
 * Comment Case Prefix
 */
const commentCasePrefix = 'comment-';

/**
 * Max User Age
 */
const maxUserAge = 65;

/**
 * Min User Age
 */
const minUserAge = 19;

/**
 * Start Year
 */
const startYear = 1950;

/**
 * Current Year
 */
const currentYear = new Date().getUTCFullYear();

/**
 * Years Options
 */
const yearsOptions = Array(currentYear - startYear + 1)
  .fill(null)
  .map((_, index) => ({
    text: (startYear + index).toString(),
    description: ''
  }))
  .sort((a, b) => (a.text > b.text ? -1 : 1));

/**
 * Months
 */
const months = [
  'Cічень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень'
];

/**
 * Months Options
 */
const monthsOptions = months.map(month => ({ text: month, description: '' }));

/**
 * Error Messages
 */
const errorMessages: { [key: string]: string } = {
  inn:
    'Перевір, будь-ласка, введені дані (має містити 10 символів та належати повнолітній особі)'
};

export {
  maxUserAge,
  minUserAge,
  startYear,
  currentYear,
  errorMessages,
  months,
  monthsOptions,
  yearsOptions,
  commentCasePrefix
};
