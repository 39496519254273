import { useEffect } from 'react';
import { FormControlProps, QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import { formattingDate } from 'src/core/utils';

/**
 * <DatePicker /> props type
 */
type DatePickerProps = FormControlProps & {
  /**
   * Question
   */
  question: QuestionModel;
  /**
   * OnChange handler
   */
  onChange: (question: QuestionModel, value: string) => void;
};

/**
 * <DatePicker /> props
 */
const useDatePickerProps = ({
  question,
  value,
  onChange
}: Partial<DatePickerProps>) => {
  const { changeIsQuestionAnswered } = useStore();

  const _onChange = (dateValue: Date | string | any) => {
    if (!onChange) return;
    const responseDate = formattingDate(dateValue);
    onChange(question as QuestionModel, responseDate);
  };

  useEffect(() => {
    const responseDate = formattingDate(new Date());

    onChange(question as QuestionModel, responseDate);
  }, []);

  useEffect(() => {
    changeIsQuestionAnswered(!!value);
  }, [value, changeIsQuestionAnswered]);

  return {
    value,
    _onChange
  };
};

export { useDatePickerProps };
